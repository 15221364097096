import React, { useState } from "react";
import { TiChevronLeftOutline, TiChevronRightOutline } from "react-icons/ti";
import "./App.scss";

import studiologo from "./b1.png";

const CARDS = 2;
const MAX_VISIBILITY = 2;

const cards = [
  {
    title: "Portfolio",
    logo: studiologo,
    location: "https://portfolio.bungfro.com/",
    content: "My Work",
  },
  {
    title: "Bungfro Inc",
    logo: studiologo,
    location: "https://us.bungfro.com/",
    content: "Game Studio",
  },
];

const Card = ({ title, content, logo, location }) => (
  <div className="card" onClick={() => (window.location.href = location)}>
    <h2>{title}</h2>
    <img src={logo} width={200} />
    <p>{content}</p>
  </div>
);

const Carousel = ({ children }) => {
  const [active, setActive] = useState(0);
  const count = React.Children.count(children);

  return (
    <div className="carousel">
      {active > 0 && (
        <button className="nav left" onClick={() => setActive((i) => i - 1)}>
          <TiChevronLeftOutline />
        </button>
      )}
      {React.Children.map(children, (child, i) => (
        <div
          className="card-container"
          style={{
            "--active": i === active ? 1 : 0,
            "--offset": (active - i) / 3,
            "--direction": Math.sign(active - i),
            "--abs-offset": Math.abs(active - i) / 3,
            pointerEvents: active === i ? "auto" : "none",
            opacity: Math.abs(active - i) >= MAX_VISIBILITY ? "0" : "1",
            display: Math.abs(active - i) > MAX_VISIBILITY ? "none" : "block",
          }}
        >
          {child}
        </div>
      ))}
      {active < count - 1 && (
        <button className="nav right" onClick={() => setActive((i) => i + 1)}>
          <TiChevronRightOutline />
        </button>
      )}
    </div>
  );
};

const check = (e) => {
  if (parseInt(e.target.value)) {
    const calc = (parseInt(e.target.value) * 52) / 12;
    document.getElementById("total").textContent = `$${calc.toFixed(2)}`
  } else {
    document.getElementById("total").textContent = `????`
  }
};
const App = () => (
  <div className="app">
    {window.location.pathname === "/services/calculator" && (
      <>
        <div class="container">
          <h1>Rates Calculator</h1>
          <div class="input-container">
            <input
              type="text"
              placeholder="Please enter the rate"
              onChange={check}
            />
            <p id="total">$0</p>
          </div>
          <footer>Powered by Bungfro Inc</footer>
        </div>
      </>
    )}
    {window.location.pathname !== "/services/calculator" && (
      <Carousel>
        {[...new Array(CARDS)].map((_, i) => (
          <Card
            key={i + 1}
            title={cards[i].title}
            content={cards[i].content}
            logo={cards[i].logo}
            location={cards[i].location}
          />
        ))}
      </Carousel>
    )}
  </div>
);

export default App;
